@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Helvetica Monospaced', sans-serif;
}

html, body, #root {
    background-color: black;
    height: 100%;
}