.header {
    font-family: 'Helvetica Monospaced', sans-serif;
    text-decoration: none;
    text-transform: uppercase;
    text-shadow: none;
    display: inline-block;
    height: 100%;
}

.header span:hover {
    cursor: pointer;
}

